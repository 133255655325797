import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { CLOSE_EMAIL_DETAILS, EmailAlertDetailsContext, SELECT_EMAIL_ALERT } from './EmailAlertDetailsContext';
import {EmailAlertResultsContext} from './EmailAlertResultsContext';
import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { timeStampToString } from '../../../utils/dateUtils';
import DynamicBannerType from './ResultTable/DynamicBannerType';
import NavigationArrows from '../../../../../common/components/Toggles/NavigationArrows';
import ExitReasonCell from './ResultTable/ExitReasonCell';

const useStyles = makeStyles((theme) => ({
    drawer: {
        minWidth: 620
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.light,
        height: 64,
        padding: '14px 20px'
    },
    infoCells: {
        borderBottom: 'none'
    },
    navigation: {
        padding: '24px 20px'
    }
}));

const EmailAlertDetails = () => {
  const classes = useStyles()
  const theme = useTheme()

  const [detailsContext, detailsContextDispatch] = React.useContext(EmailAlertDetailsContext)
  const [resultsContext, ] = React.useContext(EmailAlertResultsContext)

  const handleCloseDetails = () => {
    detailsContextDispatch({
        type: CLOSE_EMAIL_DETAILS
    })
  }

  const handleForward = () => {
      let newIndex  = detailsContext.emailAlertIndex + 1
      if(newIndex <= resultsContext.displayedResults.length){
          detailsContextDispatch({
              type: SELECT_EMAIL_ALERT,
              emailAlert: resultsContext.displayedResults[newIndex],
              emailAlertIndex: newIndex
          })
      }
  }

    const handleBack = () => {
        let newIndex  = detailsContext.emailAlertIndex - 1
        if(newIndex >= 0){
            detailsContextDispatch({
                type: SELECT_EMAIL_ALERT,
                emailAlert: resultsContext.displayedResults[newIndex],
                emailAlertIndex: newIndex
            })
        }
    }

  return (
        <Drawer anchor='right' style={{zIndex: 100001, width:'auto'}} open={detailsContext.detailsViewOpen && detailsContext.emailAlert}>
            <Box className={classes.drawer}>
                <Box display='flex' className={classes.header}>
                    <Typography variant="h3"> Email Details </Typography>
                    <Button style={{marginLeft: 'auto', fontSize: 20}} onClick={handleCloseDetails}>
                        <FontAwesomeIcon icon={faTimes} color={theme.palette.secondary.light} />
                    </Button>
                </Box>
                {detailsContext.emailAlert &&
                <>
                    <Box display='flex' className={classes.navigation}>
                        <Typography variant="h3"> {`From: ${detailsContext.emailAlert.from}`} </Typography>
                        <NavigationArrows
                            style={{marginLeft: 'auto'}}
                            onBack={handleBack} 
                            onForward={handleForward} 
                            currentOffset={detailsContext.emailAlertIndex}
                            itemsOnCurrentPage={1}
                            totalItems={resultsContext.displayedResults.length}
                            disableItemString={true}  
                        />
                    </Box> 
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.infoCells} align='right'>Message ID</TableCell>
                                <TableCell className={classes.infoCells} align='left'>{detailsContext.emailAlert.messageId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.infoCells} align='right'>From</TableCell>
                                <TableCell className={classes.infoCells} align='left'>{detailsContext.emailAlert.from}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.infoCells} align='right'>To</TableCell>
                                <TableCell className={classes.infoCells} align='left'>{detailsContext.emailAlert.to}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.infoCells} align='right'>Subject</TableCell>
                                <TableCell className={classes.infoCells} align='left'>{detailsContext.emailAlert.subject}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.infoCells} style={{verticalAlign: 'top'}} align='right'>Dynamic Banner</TableCell>
                                <TableCell className={classes.infoCells} align='left'>
                                    <DynamicBannerType type={detailsContext.emailAlert.dynamicBanner}/>
                                    <ul>
                                    {detailsContext.emailAlert.ruleChecks.map(ruleCheck =>
                                        <li>{ruleCheck}</li>
                                    )}
                                    </ul>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.infoCells} align='right'>Exit Reason</TableCell>
                                <TableCell className={classes.infoCells} align='left'><ExitReasonCell reason={detailsContext.emailAlert.exitReason} aiRiskLabel={detailsContext.emailAlert.aiRiskLabel}/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.infoCells} align='right'>Date/Time</TableCell>
                                <TableCell className={classes.infoCells} align='left'>{timeStampToString(detailsContext.emailAlert.dateTime)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>}
            </Box>
        </Drawer>
  )
}

export default EmailAlertDetails