import { Grid } from '@material-ui/core'
import React from 'react'
import EmailAlertDetails from './EmailAlertDetails'
import { EmailAlertResultsContext, EmailAlertResultsReducerActionTypes } from './EmailAlertResultsContext'
import { EmailAlertSearchContext } from './EmailAlertSearchContext'
import ResultPagination from './ResultPagination'
import ResultTable from './ResultTable/ResultTable'
import SearchForm from './SearchForm'
import { AuthenticationContext } from '../../../App/AuthenticationContext'
import { mapEmailAlertResults, searchEmailAlerts } from '../../../api/search'




const EmailAlertSearch = () => {

    const [searchContext, ] = React.useContext(EmailAlertSearchContext)

    const [, resultContextDispatch] = React.useContext(EmailAlertResultsContext)

    const [authContext, ] = React.useContext(AuthenticationContext)

    const executeSearch = () => {

        searchEmailAlerts(authContext, searchContext.searchState, searchContext.pagination).then(response => {
            let processedResults = mapEmailAlertResults(response.data.emails)
            console.log(processedResults)
            resultContextDispatch({
                type: EmailAlertResultsReducerActionTypes.UPDATE_RESULTS,
                payload: {
                    results: processedResults,
                    totalItems: response.data.count,
                    itemsOnCurrentPage : processedResults.length
                }
            })
        })

    }

    React.useEffect(executeSearch, [searchContext])

    return (
        <>
            <Grid container>
                <Grid xs={12}>
                    <SearchForm/>
                </Grid>
                <Grid xs={12}>
                    <ResultPagination/>
                </Grid>
                <Grid xs={12}>
                    <ResultTable/>
                </Grid>
            </Grid>
            <EmailAlertDetails/>
        </>
       
    )
}

export default EmailAlertSearch
