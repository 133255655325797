import React from 'react';
import { faBan, faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '@material-ui/core';

const DynamicBannerType = ({type}) => {
    const theme = useTheme()
    const dynamicBannerCells = {
        info: <span><FontAwesomeIcon icon={faInfoCircle} color={theme.warningColors.info}/> Info</span>,
        warn: <span><FontAwesomeIcon icon={faExclamationTriangle} color={theme.warningColors.warning}/> Warning</span>,
        critical: <span><FontAwesomeIcon icon={faExclamationCircle} color={theme.warningColors.critical}/> Critical</span>,
        blacklist: <span><FontAwesomeIcon icon={faBan} color={theme.warningColors.blacklist}/> Blocklist</span>,
        none:  <span>None</span>
    }

    return(dynamicBannerCells[type])
}

export default DynamicBannerType

