import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        'minWidth': '16px',
        'userSelect': 'none',
        'appearance': 'none',
        'fontSize': 14,
        'color': theme.palette.type === 'light' ? 'currentColor' : '#cccccc',
        'width': '100%',
        'height': '20px',
        'margin': 0,
        'display': 'block',
        'background': 'none',
        'boxSizing': 'content-box',
        'WebkitTapHighlightColor': 'transparent',
        'borderRadius': '6px',
        'border': `1px solid ${theme.colors[2]}`,
        'padding': '6px 36px 6px 12px',
        '&:focus': {
            outline: 'none',
            //dark theme point
            borderColor: theme.palette.primary.main6
        },
        '&::placeholder': {
            color: theme.colors[4],
        },
        '&: disabled': {
            outline:
                theme.palette.type === 'light'
                    ? '1px solid #B2B2B2'
                    : '1px solid rgba(255,255,255,0.3)',
        },
    },
}))


const CommonInput = ({
    value,
    onChange,
    onEnter,
    placeholder,
    className,
    style,
    id
}) => {
    const classes = useStyles()

    const handleKeyDown = (event) => {
        if (onEnter && event.key === 'Enter') {
            onEnter()
        }
    }

    return (
        <input
            id={id}
            value={value}
            className={`${classes.root} ${className}`}
            style={style}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onChange={event => onChange(event.target.value)}
        />

    )
}
CommonInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
}
export default CommonInput
