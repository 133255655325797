import { 
    makeStyles, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
} from '@material-ui/core'
import React from 'react'
import { timeStampToString } from '../../../../utils/dateUtils'
import { EmailAlertDetailsContext, SELECT_EMAIL_ALERT } from '../EmailAlertDetailsContext'
import {EmailAlertResultsContext} from '../EmailAlertResultsContext'
import DynamicBannerType from './DynamicBannerType'


const useStyles = makeStyles((theme) => ({
    tableCell: {
        padding: "12px 20px"
    },
    tableHeaderCell: {
        padding: "12px 20px",
        fontWeight: 700
    }
}))

  
const headCells = [
    { id: 'messageId', label: 'Message ID' },
    { id: 'from', label: 'From' },
    { id: 'to', label: 'To' },
    { id: 'subject', label: 'Subject' },
    { id: 'dynamicBanner', label: 'Dynamic Banner' },
    { id: 'dateTime', label: 'Date/Time' },
]
  


const ResultPagination = () => {

    const classes = useStyles()

    const [resultContext,  ] = React.useContext(EmailAlertResultsContext)
    const [, detailsContextDispatch] = React.useContext(EmailAlertDetailsContext)

    const handleClick = (event, row, index) =>{
        detailsContextDispatch({
            type: SELECT_EMAIL_ALERT,
            emailAlert: row,
            emailAlertIndex: index
        })
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            className={classes.tableHeaderCell}
                        >
                            {headCell.label}
                        </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultContext.displayedResults.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                onClick={(event) => handleClick(event, row, index)}
                                key={`${index}-${row.messageId}`}
                            >
                                <TableCell style={{maxWidth:400, textOverflow: 'ellipsis', overflow: 'hidden'}} scope="row" className={classes.tableCell} >
                                    {row.messageId}
                                </TableCell>
                                <TableCell className={classes.tableCell}>{row.from}</TableCell>
                                <TableCell className={classes.tableCell}>{row.to}</TableCell>
                                <TableCell className={classes.tableCell}>{row.subject}</TableCell>
                                <TableCell className={classes.tableCell}><DynamicBannerType type={row.dynamicBanner}/></TableCell>
                                <TableCell className={classes.tableCell}>{ timeStampToString(row.dateTime)}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ResultPagination


