import React from 'react'
import PageHeader from '@mc/common/components/Page/PageHeader'
import {  Box } from '@material-ui/core'
import OverviewTab from './tabs/OverviewTab/OverviewTab'
import SearchTab from './tabs/Search/SearchTab'
import Tabs from '@mc/common/components/Tabs/Tabs'
import { AuthenticationContext } from '../App/AuthenticationContext'




const EmailAlerts = ({ locationHash }) => {
    const [currentTab, setTab] = React.useState('')

    const [authState,] = React.useContext(AuthenticationContext)

    React.useEffect(() => {
        if (authState.isImpersonating && locationHash !== '') {
            setTab(locationHash.split('#')[1])
        }
    }, [locationHash, authState.isImpersonating])

    const tabOptions = [
        { value: '', label: 'Overview', },
        { value: 'search', label: 'Search', },
    ]
    const handleChangeTab = (value) => {
        let newTab = value || ''
        window.location.hash = newTab
        setTab(newTab)
    }


    return (
        < >
            <PageHeader pageTitle="Dynamic Banners" subText="CyberGraph Dynamic Banners" />
            {
                authState.isImpersonating ? 
                    <>
                        <Box p={2} style={{ padding: '0px 0px 30px 0px' }}>
                            <Tabs style={{ fontSize: '16px' }} value={currentTab} onChange={handleChangeTab} options={tabOptions} />
                        </Box>
                        <>
                        {currentTab === '' && <OverviewTab/>}
                        {currentTab === 'search' && <SearchTab />}
                        </>
                    </>
                : 
                    <OverviewTab/>
                
            }
            
        </>
    )
}

export default EmailAlerts
