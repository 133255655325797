import React from 'react'
import EmailAlerts from '../EmailAlerts/EmailAlerts'
import ProtectedViewLayout from '../App/ProtectedViewLayout';


const DynamicBannersPage = ({ location }) => {
    return (
        <ProtectedViewLayout location={location}>
            <EmailAlerts locationHash={location.hash}/>
        </ProtectedViewLayout >
    );
}

export default DynamicBannersPage