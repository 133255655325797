import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@material-ui/core';
import React from 'react';


export const CODEBREAKER_EXIT_REASONS = {
    EXIT_REASON_AI: 'ai',
    EXIT_REASON_AI_SUPPRESSED: 'ai_suppressed',
    EXIT_REASON_AI_NOT_SUPPRESSED: 'ai_not_suppressed',
    EXIT_REASON_GENERAL: 'general',
    EXIT_REASON_DEBUG: 'debug',
    EXIT_REASON_BLACKLISTED: 'blacklisted',
    EXIT_REASON_WHITELISTED_RULES_FAILED: 'whitelist_rules_failed',
    EXIT_REASON_WHITELISTED: 'whitelisted',
    EXIT_REASON_STRONG_COMM: 'strong_comm',
    EXIT_REASON_NO_WARNINGS: 'no_warnings',
    EXIT_REASON_CONTENT: 'content',
}

export const exitReasonCells = {
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_AI]: {
        text: `Targeted Bannering`, 
        infoText: `Customer is using targeted bannering instead of broad bannering resulting in the decision to include (or exclude) the banner.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_AI_SUPPRESSED]: {
        text: `Banner Suppressed`, 
        infoText: `Customer is using targeted bannering instead of broad bannering, and the system determined it did not meet the criteria for banner placement.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_AI_NOT_SUPPRESSED]: {
        text: `Banner Not Suppressed`, 
        infoText: `Customer is using targeted bannering instead of broad bannering, and the system determined it met the criteria for banner placement.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_GENERAL]: {
        text: `Banner Applied – Email matches one or more Dynamic Banner Rule(s)`, 
        infoText: `Sender SPF passed, the email did not meet blocklist criteria, there is not a strong communication pattern between recipients, but for another reason (e.g. use of obscure characters in sender address, sender has a name similar to someone else in recipient's contacts, or some other relevant reason) the email met the criteria for a banner.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_DEBUG]: {
        text: `Debug`, 
        infoText: `Email was sent with debugging subject line to demonstrate banners working.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_BLACKLISTED]: {
        text: `Banner Applied - Sender Address, Domain, or IP is currently marked as Dangerous`, 
        infoText: `This email matches at least one of the criteria of emails reported by users (IP address, Domain, or From address).`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_WHITELISTED_RULES_FAILED]: {
        text: `Sender SPF Failed`, 
        infoText: `SPF check results failed for sender even though the sender may be on the customer’s safelist or is one of the customer’s domains.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_WHITELISTED]: {
        text: `Safe Listed`, 
        infoText: `The email’s domain is on the customer’s safelist or is one of the customer’s domains.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_STRONG_COMM]: {
        text: `Strong Communication Pattern`, 
        infoText: `Strong communication exists between the from-field and the recipient(s), thus no banners applied.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_NO_WARNINGS]: {
        text: `No Banner Required`, 
        infoText: `No characteristics meriting banner placement found.`
    },
    [CODEBREAKER_EXIT_REASONS.EXIT_REASON_CONTENT]: {
        text: `Overridden Strong Communication Pattern`, 
        infoText: `While strong communication exists between the from-field and the recipient(s), the email contains at least one other notable characteristic (e.g. use of obscure characters in sender address, sender has a name similar to someone else in recipient's contacts, or some other relevant reason) that subsequently resulted in a banner being applied.`
    },
}


const ExitReasonCell = ({reason, aiRiskLabel=null}) => {
    const theme = useTheme()
    
    let reasonOb = exitReasonCells[reason]

    if (reason===CODEBREAKER_EXIT_REASONS.EXIT_REASON_AI && aiRiskLabel!==null){
        reasonOb = aiRiskLabel ? exitReasonCells[CODEBREAKER_EXIT_REASONS.EXIT_REASON_AI_NOT_SUPPRESSED] : exitReasonCells[CODEBREAKER_EXIT_REASONS.EXIT_REASON_AI_SUPPRESSED]
    }

    return (
        <span><FontAwesomeIcon icon={faInfoCircle} color={theme.warningColors.info}  title={reasonOb.infoText}/> {reasonOb.text}</span>
    )

}

export default ExitReasonCell

