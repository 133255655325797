import React, { useRef } from 'react'
import { makeStyles, Box, Grid } from '@material-ui/core'
import { AppContext, UPDATE_TIME_WINDOW, UPDATE_EMAIL_ALERT_DATA } from '../../../App/AppContext'
import AlertGraphCard from './AlertGraphCard'
import AlertsTriggeredCard from './AlertsTriggeredCard'
import DomainSafelistCard from './DomainSafelistCard'
import UserReportsCard from './UserReportsCard'
import { fetchCodebreakerDashboardData } from '../../../api/codebreaker'
import { processRawCodeBreakerSummaryData } from '../../../utils/codebreakerData'
import TimeFrameToggle from '@mc/common/components/Toggles/TimeFrameToggle';
import { AuthenticationContext } from '../../../App/AuthenticationContext'



const useStyles = makeStyles((theme) => ({
    //This prop should be abstracted 
    summaryGrid: {
        flexGrow: 1,
        '@media print': {
            width: 1000,
            margin: 20,
        }
    },
    printButton: {
        height: 32,
        marginBottom: 18,
        borderRadius: 6
    },


}));


const OverviewTab = () => {
    const classes = useStyles()
    const componentRef = useRef()
    const [authState,] = React.useContext(AuthenticationContext)

    const [appState, appDispatch] = React.useContext(AppContext)
    const getUpdateCodebreakerDashboardData = () => {
        fetchCodebreakerDashboardData(appState.timeWindow, authState).then(res =>
            appDispatch(
                {
                    type: UPDATE_EMAIL_ALERT_DATA,
                    payload: processRawCodeBreakerSummaryData(res.data, authState.customerInfo.computed_users)
                })

        )
    }

    React.useEffect(getUpdateCodebreakerDashboardData, [appState.timeWindow])

    const handleTimeWindow = (newTimeWindow) => {
        appDispatch({
            type: UPDATE_TIME_WINDOW,
            payload: newTimeWindow
        })
    }



    return (
        < >
            <Box component="span">
                <TimeFrameToggle timeWindow={appState.timeWindow} onChange={handleTimeWindow} />
            </Box>
            <Box ref={componentRef} className={classes.summaryGrid}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <AlertGraphCard display={{ md: 'none' }} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <AlertsTriggeredCard emailAlertData={appState.emailAlertData} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <DomainSafelistCard emailAlertData={appState.emailAlertData}></DomainSafelistCard>
                    </Grid>
                    <Grid item xs={12}>
                        <UserReportsCard emailAlertData={appState.emailAlertData} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default OverviewTab
