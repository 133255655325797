import React from 'react'
import PropTypes from 'prop-types'
import TableDataCard from '@mc/common/components/Cards/TableDataCard'
import { useTheme, Typography, Box } from '@material-ui/core'
import { RULE_LEVELS } from '../../../utils/codebreakerData';
import PercentChangeText from '@mc/common/components/Text/PercentChangeText'



const AlertsTriggeredCard = ({ emailAlertData }) => {
    const theme = useTheme()

    const colHeaders = [
        { displayName: 'Rule', key: 'rule' },
        { displayName: 'Count', key: 'count' },
        { displayName: '% Of Total', key: 'percentOfTotal' },
        { displayName: '% Change', key: 'percentChange' },
    ]


    const rows = Object.keys(RULE_LEVELS).map((rule) => {
        let totalEmails = emailAlertData.sum_curr_alerts
            + emailAlertData.sum_curr_emails
        let curRuleAlerts = emailAlertData.curr_alert_types[rule]
        let prevRuleAlerts = emailAlertData.prev_alert_types[rule]

        let percentOfTotal = `${totalEmails && curRuleAlerts ?
            (100 * curRuleAlerts / totalEmails).toFixed(2)
            : 0
            }% `

        let percentChange = prevRuleAlerts && curRuleAlerts
            ? Math.ceil(100 * (curRuleAlerts - prevRuleAlerts) / prevRuleAlerts)
            : 0

        return {
            rule: rule.substr(0, rule.length - 4),
            count: emailAlertData.curr_alert_types[rule] || 0,
            percentOfTotal: percentOfTotal,
            percentChange: <PercentChangeText score={percentChange} passedStyle={{ float: 'right' }} />
        }
    });

    return (
        <TableDataCard
            headerText='Dynamic Banner Types Triggered'
            subText='Dynamic Banners triggered by type of banner'
            rows={rows}
            colHeaders={colHeaders}
        >
            <Box display="flex" justifyContent="left">
                {
                    [
                        { level: 'info', title: 'Info' },
                        { level: 'warning', title: 'Warning' },
                        { level: 'critical', title: 'Critical' },
                        { level: 'blacklist', title: 'Reported' }].map(severity => (
                            <Box data-severity={severity.level} key={severity.level} m={2} style={{
                                color: theme.warningColors[severity.level],
                                maxWidth: 'min-content',
                                textAlign: 'center'
                            }}>
                                <Typography variant='h6'>
                                    {severity.title}
                                </Typography>
                                <Typography variant='h4'>
                                    {emailAlertData.curr_levels[severity.level] || 0}
                                </Typography>

                            </Box>
                        ))

                }
            </Box>
        </TableDataCard>
    )
}

AlertsTriggeredCard.propTypes = {
    emailAlertData: PropTypes.object.isRequired,
}

export default AlertsTriggeredCard




