import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles,
    Typography,
    Card,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
//TODO: use either classnames or clsx
import classnames from 'classnames'
import { textToIDFormat } from "../../lib/strings";

const useStyles = makeStyles((theme) => ({
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
        maxHeight: 555,
    },
    cardText:
    {
        paddingLeft: 20,
    },
    row: {
        "&:last-child th, &:last-child td": {
            borderBottom: 0,
        },
    },
    hoverable: {
        '&:hover': {
            background: theme.backgrounds[1],
        },
    },
    tableRowHeader: {
        fontWeight: 600,
    },
    tableCell: {
        maxWidth: 300
    }

}));

//TODO: Should take arrays of objects to map the row data by keys. Probably safer
const TableDataCard = ({ colHeaders, headerText, subText, children, rows, maxHeight=352 }) => {
    const classes = useStyles();
    const [tableDataCardId] = React.useState(textToIDFormat(headerText,'card'),[headerText]);

    const colKeys = colHeaders.map(colHeader => { return colHeader.key })

    return (
        <Card id={tableDataCardId} className={classes.card}>
            <Typography variant="h2" gutterBottom className={classes.cardText}>
                {headerText}
            </Typography>
            {subText &&
                <Typography color="textPrimary" variant="body2" className={classes.cardText}>
                    {subText}
                </Typography>
            }
            {children}
            <Divider />
            <TableContainer style={{ maxHeight: maxHeight, }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {colHeaders.map((colHeader, index) => {
                                let align = 'right'
                                if (index === 0) align = 'left'
                                return (
                                    <TableCell key={index} className={classes.tableCell}>
                                        <Typography
                                            align={align}
                                            variant="subtitle2"
                                            color="textPrimary"
                                            classes={{ root: classes.tableRowHeader }}
                                        >
                                            {colHeader.displayName}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                className={classnames(classes.row, classes.hoverable)}
                            >
                                {colKeys.map((key, index) => {
                                    let align = 'right'
                                    if (index === 0) align = 'left'
                                    return (
                                        <TableCell key={index} className={classes.tableCell}>
                                            <Typography
                                                align={align}
                                                variant="subtitle2"
                                                color="textPrimary"
                                            >
                                                {row[key]}
                                            </Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Card >
    )
}
TableDataCard.propTypes = {
    colHeaders: PropTypes.array.isRequired,
    headerText: PropTypes.string.isRequired,
    subText: PropTypes.string,
    children: PropTypes.node,
    rows: PropTypes.array.isRequired,
    maxHeight: PropTypes.number
}

export default TableDataCard
