import React from 'react'
import PropTypes from 'prop-types'
import { LAST_DAY, LAST_MONTH, LAST_WEEK } from '@mc/common/components/Toggles/TimeFrameToggle'

export const EmailAlertSearchReducerActionTypes = {
    UPDATE_EMAIL_SEARCH_STATE : 'UPDATE_EMAIL_SEARCH_STATE',
    RESET_EMAIL_SEARCH_STATE : 'RESET_EMAIL_SEARCH_STATE',
    UPDATE_EMAIL_SEARCH_PAGINATION : 'UPDATE_EMAIL_SEARCH_PAGINATION',
}

export const dynamicBannerTypes = {
    info: {label: 'Info', value :'info'},
    warn: {label: 'Warning', value :'warn'},
    critical: {label: 'Crititcal', value :'critical'},
    blacklist: {label: 'Blocklist', value :'blacklist'},
    none: {label: 'None', value: 'none'},
    all: {label: 'All Types', value :'all'}
}

export const dateRanges = {
    [LAST_DAY]: {label: 'Past 24 Hours', value : LAST_DAY},
    [LAST_WEEK]: {label: 'Past 7 Days', value : LAST_WEEK},
    [LAST_MONTH]: {label: 'Past 30 Days', value : LAST_MONTH},
}

export const EmailAlertSearchContext = React.createContext();

export const intitialEmailAlertSearchState = {
    messageId: '',
    from: '',
    to: '',
    subject: '',
    dynamicBannerType: dynamicBannerTypes.all,
    dateRange: dateRanges[LAST_DAY],
}

export const initialEmailAlertSearchPagination = {
    perPage: 10,
    currentOffset: 1,
}

export const initiaEmailAlertSearchContext = {
    searchState: intitialEmailAlertSearchState,
    pagination: initialEmailAlertSearchPagination,
};


export const emailAlertSearchReducer = (state, action) => {
    switch (action.type) {
        case EmailAlertSearchReducerActionTypes.UPDATE_EMAIL_SEARCH_STATE:
            return {
                ...state,
                searchState: action.payload,
                pagination: initialEmailAlertSearchPagination
            }
        case EmailAlertSearchReducerActionTypes.RESET_EMAIL_SEARCH_STATE:
            return initiaEmailAlertSearchContext
        case EmailAlertSearchReducerActionTypes.UPDATE_EMAIL_SEARCH_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            }
        default:
            return initiaEmailAlertSearchContext;
    }
}

const EmailAlertSearchContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(emailAlertSearchReducer, initiaEmailAlertSearchContext);
    return (
        < EmailAlertSearchContext.Provider value={[state, dispatch]}>
            {children}
        </EmailAlertSearchContext.Provider>
    );
}

EmailAlertSearchContextProvider.propTypes = {
    children: PropTypes.node,
}

export default EmailAlertSearchContextProvider