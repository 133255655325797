import { Box, makeStyles, Typography} from '@material-ui/core'
import React from 'react'
import NavigationArrows from '../../../../../common/components/Toggles/NavigationArrows';
import { EmailAlertResultsContext } from './EmailAlertResultsContext';
import { EmailAlertSearchContext, EmailAlertSearchReducerActionTypes } from './EmailAlertSearchContext';


const useStyles = makeStyles((theme) => ({
    paginationContainer: {
        float: 'right',
        paddingTop: 30,
        paddingBottom: 20,
    },
    dropdownInput:{
        width: '100%',
        fontSize: theme.typography.body1,
        paddingLeft:'5px',
        height: '32px',
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
        boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)'
        
    },
}));

const ResultPagination = () => {
    const classes = useStyles()

    const [searchContext, searchContextDispatch] = React.useContext(EmailAlertSearchContext)
    const [resultContext, ] = React.useContext(EmailAlertResultsContext)
    const handlePerPageChange = elem => {
        const val = parseInt(elem.target.value)
        searchContextDispatch({
            type: EmailAlertSearchReducerActionTypes.UPDATE_EMAIL_SEARCH_PAGINATION,
            payload: {
                ...searchContext.pagination,
                perPage: val,
                currentOffset: 1
            }
        })
    }
    
    const handlePageBack = () => {
        if(searchContext.pagination.currentOffset > searchContext.pagination.perPage){
            searchContextDispatch({
                type: EmailAlertSearchReducerActionTypes.UPDATE_EMAIL_SEARCH_PAGINATION,
                payload: {
                    ...searchContext.pagination,
                    currentOffset: searchContext.pagination.currentOffset - searchContext.pagination.perPage
                }
            })
        }
        
    }

    const handlePageForward = () => {
        if(searchContext.pagination.currentOffset + searchContext.pagination.perPage <= resultContext.totalItems){
            searchContextDispatch({
                type: EmailAlertSearchReducerActionTypes.UPDATE_EMAIL_SEARCH_PAGINATION,
                payload: {
                    ...searchContext.pagination,
                    currentOffset: searchContext.pagination.currentOffset + searchContext.pagination.perPage
                }
            })
        }

       
    }

    return (
        <Box  display='flex' className={classes.paginationContainer}>
            <Typography variant='body2' style={{padding: '5px 10px', whiteSpace:'nowrap'}}>
                Show
            </Typography>
            <select
                className={classes.dropdownInput}
                value={searchContext.pagination.perPage}
                onChange={handlePerPageChange}
            >
                <option key={0} value={10}>10</option>
                <option key={1} value={20}>20</option>
                <option key={2} value={50}>50</option>
                <option key={3} value={100}>100</option>
            </select>
            <NavigationArrows 
                onBack={handlePageBack} 
                onForward={handlePageForward} 
                itemsOnCurrentPage={resultContext.itemsOnCurrentPage}
                currentOffset={searchContext.pagination.currentOffset -1}
                totalItems={resultContext.totalItems} 
            />
        </Box>
    )
}

export default ResultPagination


