import React from 'react'
import TableDataCard from '@mc/common/components/Cards/TableDataCard'
import Tabs from '@mc/common/components/Tabs/Tabs'
import { useTheme, Button, Box, TextField } from '@material-ui/core'
import { isWhitelisted } from '../../utils/safeListUtils'
import { removeWhitelistedDomain, whitelistDomain } from '../../../api/codebreaker'
import { AppContext, UPDATE_EMAIL_ALERT_DATA } from '../../../App/AppContext'
import { AuthenticationContext } from '../../../App/AuthenticationContext'
import { validateDomain } from '@mc/common/lib/strings'

const DomainSafelistCard = () => {
    const theme = useTheme()

    const tabOptions = [
        { value: '1', label: 'Triggered Domain' },
        { value: '2', label: 'Current Safelist' },
    ]
    const [authState,] = React.useContext(AuthenticationContext)
    const [tableTab, setTableTab] = React.useState('1')
    const [addingCustomDomain, setAddingCustomDomain] = React.useState(false)
    const [customDomainFormErrorText, setCustomDomainFormError] = React.useState('')
    const [customDomainValue, setCustomDomainValue] = React.useState('');

    const [appState, appDispatch] = React.useContext(AppContext)

    const handleChangeTableTab = () => {
        let newTab = tableTab === '1' ? '2' : '1'
        setTableTab(newTab)
    }

    const handleWhitelistDomain = (domain, customField = false) => {
        if (customField && (!domain || !domain.length || !validateDomain(domain))) {
            throw new Error('Invalid domain name.')
        }

        let is_whitelisted = isWhitelisted(domain, appState.emailAlertData.whitelisted_domains);
        if (is_whitelisted && customField) {
            throw new Error('Domain is already whitelisted.')
        } else if (is_whitelisted) {
            removeWhitelistedDomain(domain, authState)
            if (appState.emailAlertData.whitelisted_domains) {
                appDispatch(
                    {
                        type: UPDATE_EMAIL_ALERT_DATA,
                        payload: {
                            whitelisted_domains:
                                appState.emailAlertData.whitelisted_domains.map(item => {
                                    if (item.domain === domain) {
                                        item.status = 'disabled'
                                    }
                                    return item
                                }),
                            ...appState.emailAlertData
                        }
                    })
            }
        } else {
            whitelistDomain(domain, authState)
            let whitelistCopy = appState.emailAlertData.whitelisted_domains
            if (appState.emailAlertData.whitelisted_domains) {
                let match = whitelistCopy.find(
                    item => item.domain === domain
                )
                if (match) {
                    match.status = 'active'
                }
                else {
                    whitelistCopy.unshift(
                        { 'domain': domain })
                }

                appDispatch(
                    {
                        type: UPDATE_EMAIL_ALERT_DATA,
                        payload: {
                            whitelisted_domains: whitelistCopy,
                            ...appState.emailAlertData
                        }
                    })
            }

        }
    }

    const toggleAddingCustomDomainForm = () => {
        setCustomDomainFormError('')
        setCustomDomainValue('')
        setAddingCustomDomain(!addingCustomDomain)
    }

    const handleCustomDomainValue = (event) => {
        setCustomDomainValue(event.target.value)
        setCustomDomainFormError('')
    }
    const handleAddCustomDomainValue = () => {
        try {
            handleWhitelistDomain(customDomainValue, true)
            setCustomDomainValue('')
        } catch (e) {
            setCustomDomainFormError(e.message)
        }

    }

    const triggerDomainColHeaders = [
        { displayName: 'Domain', key: 'domain' },
        { displayName: 'Count', key: 'count' },
        { displayName: 'Safelist', key: 'safelist' },
    ]

    const safelistColHeaders = [
        { displayName: 'Domain', key: 'domain' },
        { displayName: 'Safelist', key: 'safelist' },
    ]

    let currDomainKeys = appState.emailAlertData.curr_domains ? Object.keys(appState.emailAlertData.curr_domains) : []

    const sliceSize = currDomainKeys.length < 50
        ? currDomainKeys.length : 50

    const triggerDomainRows = currDomainKeys.slice(0, sliceSize).map(domain => {
        let whitlisted = isWhitelisted(domain, appState.emailAlertData.whitelisted_domains)
        let color = whitlisted ? theme.rainbowColors.red : theme.palette.primary.main
        let buttonText = whitlisted ? 'REMOVE -' : 'ADD +'
        return {
            domain: domain || 'unspecified',
            count: appState.emailAlertData.curr_domains[domain] || 0,
            safelist: <Button onClick={() => handleWhitelistDomain(domain)} style={{ color: color }}>{buttonText}</Button>,
        }
    })

    const safelistRows = appState.emailAlertData.whitelisted_domains.map(domain => {
        let whitlisted = isWhitelisted(domain.domain, appState.emailAlertData.whitelisted_domains)
        let color = whitlisted ? theme.rainbowColors.red : theme.palette.primary.main
        let buttonText = whitlisted ? 'REMOVE -' : 'ADD +'
        return {
            domain: domain.domain || 'unspecified',
            safelist: <Button  onClick={() => handleWhitelistDomain(domain.domain)} style={{ color: color }}>{buttonText}</Button>,
        }
    })

    return (
        <TableDataCard
            headerText='Domain Safelist'
            subText='As an admin you may safelist entire domains for your organization. Individuals may only safelist single addresses for themselves.'
            rows={tableTab === '1' ? triggerDomainRows : safelistRows}
            colHeaders={tableTab === '1' ? triggerDomainColHeaders : safelistColHeaders}
            maxHeight={300}
        >
            <Box p={2}>
                <Button id='add-domain-toggle' variant="contained" onClick={toggleAddingCustomDomainForm}>
                    Add Domain
                </Button>
                {addingCustomDomain &&
                    <form style={{ paddingTop: 5 }} noValidate autoComplete="off">
                        <TextField
                            value={customDomainValue}
                            error={customDomainFormErrorText.length > 0}
                            id="add-custom-domain-text"
                            label="ex. domain.com"
                            helperText={customDomainFormErrorText}
                            onChange={handleCustomDomainValue}
                        />
                        <Button id='add-domain-submit'
                            style={{ verticalAlign: 'bottom', }}
                            onClick={handleAddCustomDomainValue}
                        >
                            Add
                        </Button>
                    </form>
                }
            </Box>
            <Box p={2}>
                <Tabs style={{ fontSize: '14px' }} value={tableTab} onChange={handleChangeTableTab} options={tabOptions} />
            </Box>
        </TableDataCard>
    )
}

export default DomainSafelistCard




