import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box, Typography, Button } from '@material-ui/core'
import TableDataCard from '@mc/common/components/Cards/TableDataCard'
import { undoWhitelistedReport } from '../../../api/codebreaker'
import { AuthenticationContext } from '../../../App/AuthenticationContext'

const useStyles = makeStyles((theme) => ({
    blacklistedText: {
        color: theme.rainbowColors.red
    }

}))


const ActionField = ({ report }) => {
    const classes = useStyles()

    const [reportAction, reportActionDispatch] = React.useState(report.action)
    const [authState, ] = React.useContext(AuthenticationContext)

    const handleRevertSafelist = (reportId) => {
        undoWhitelistedReport(reportId, authState).then(() => {
            reportActionDispatch('whitelist_reverted')
        })

    }

    return (
        <Box component='span' display="flex" justifyContent='right'>
            <Typography className={reportAction && reportAction === 'blacklist' ? classes.blacklistedText : ''}
                style={{ margin: 'auto 0' }}
                component='span'
                variant="subtitle2"
                color="textPrimary">
                {reportAction && reportAction === 'blacklist' ? 'Reported' : 'SafeListed'}
            </Typography>
            {
                reportAction === 'whitelist_reverted' &&
                <Typography style={{ paddingLeft: 3, fontWeight: 600 }}
                    component='span'
                    variant="subtitle2"
                    color="textPrimary">
                    (Reverted)
                </Typography>
            }
            {
                reportAction === 'whitelist' &&
                <Button style={{ paddingRight: 0 }} color="primary" onClick={() => handleRevertSafelist(report.id)}>Revert</Button>
            }
        </Box >

    )

}
ActionField.propTypes = {
    report: PropTypes.object.isRequired
}

const UserReportsCard = ({ emailAlertData }) => {

    const colHeaders = [
        { displayName: 'Sender', key: 'sender', },
        { displayName: 'Reporter', key: 'reporter', },
        { displayName: 'Action', key: 'action', },
        { displayName: 'Reason', key: 'reason', },
        { displayName: 'Date & Time (UTC)', key: 'timestamp', },
    ]


    const rows = emailAlertData.userReports.map(report => {
        return {
            sender: report.reportee_email.trim() || 'none',
            reporter: report.reporter_email.trim() || 'none',
            action: <ActionField report={report}></ActionField>,
            reason: report.category || 'none',
            timestamp: report.added || 'none',
        }
    });

    return (
        <TableDataCard
            headerText='User Reports'
            rows={rows}
            colHeaders={colHeaders}
        >
        </TableDataCard>
    )
}

UserReportsCard.propTypes = {
    emailAlertData: PropTypes.object.isRequired,
}

export default UserReportsCard




