import React from 'react'
import EmailAlertDetailsContextProvider from './EmailAlertDetailsContext'
import EmailAlertResultsContextProvider from './EmailAlertResultsContext'
import EmailAlertSearch from './EmailAlertSearch'
import EmailAlertSearchContextProvider from './EmailAlertSearchContext'

const SearchTab = () => {

    return (
        <EmailAlertSearchContextProvider>
            <EmailAlertResultsContextProvider>
                <EmailAlertDetailsContextProvider>
                    <EmailAlertSearch/>
                </EmailAlertDetailsContextProvider>
            </EmailAlertResultsContextProvider>
        </EmailAlertSearchContextProvider>
       
    )
}

export default SearchTab
