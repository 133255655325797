import Axios from "axios";
import { getRegionalizedUrl, TRACKING_API } from "@mc/common/lib/url-utils";


export const searchEmailAlerts = (
    authContext,
    searchParams,
    paginationParams

) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/search/emails',
        data: JSON.stringify({
            user_email: authContext.email,
            session: authContext.session,
            search_params: {
                ...searchParams,
                dynamicBannerType: searchParams.dynamicBannerType.value,
                dateRange: searchParams.dateRange.value,
            },
            pagination_params: paginationParams
        }),
        headers:{
            "Content-Type": "application/json",
        }
    })
}

export const mapEmailAlertResults = (rawEmailAlertData) => {
    if(!rawEmailAlertData) return [];

    return rawEmailAlertData.map(email =>{
        return { 
            messageId: email.message_id || '', 
            from: email.from_field || '', 
            to: email.rcpt_to_field || '', 
            subject: email.subject_field || '', 
            dynamicBanner: email.dynamicBannerType, 
            dateTime: email.timestamp || 0,
            ruleChecks: email.rule_checks || [],
            exitReason: email.exit_reason || '',
            aiRiskLabel: email.ai_risk_label || null
        }
    })


}