import React from 'react'
import PropTypes from 'prop-types'


export const EmailAlertResultsReducerActionTypes = {
    UPDATE_RESULTS : 'UPDATE_RESULTS',
    UPDATE_ORDER_PARAMS : 'UPDATE_ORDER_PARAMS',
    UPDATE_FILTER_PARAM : 'UPDATE_FILTER_PARAM',
}



// MaterialUI keywords for sort direction
export const ORDER_ASC = 'asc'
export const ORDER_DEC = 'desc'


export const EmailAlertResultsContext = React.createContext();

export const initiaEmailAlertResultsContext = {
    fetchedResults: [],
    displayedResults: [],
    sortOrder : ORDER_ASC,
    sortOrderBy :'dateTime',
    filterTerm: '',
    totalItems: 0,
    itemsOnCurrentPage: 0,
};

export const filterEmailAlertResults = (filterTerm, fullResults) => {
    
    if (filterTerm === ''){
        return fullResults
    }

    return fullResults.filter(o =>
        Object.keys(o).some(
            k => {
                if(typeof o[k] === 'string'){
                    return o[k].toLowerCase().includes(filterTerm.toLowerCase())
                }
                return false
            }
        )
    )   
}

export const  descendingComparator = (a, b, orderBy) =>{
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
}

export const  stableSort = (array, sortOrder, sortOrderBy)=> {
    const comparator = sortOrder === 'desc'
        ? (a, b) => descendingComparator(a, b, sortOrderBy)
        : (a, b) => -descendingComparator(a, b, sortOrderBy)

    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export const filterAndSortResults = (rawResults, filterTerm, sortOrder, sortOrderBy) => {
    return stableSort(
        filterEmailAlertResults(filterTerm, rawResults),
        sortOrder,
        sortOrderBy
    )
}

export const emailAlertResultsReducer = (state, action) => {
    switch (action.type) {
        case EmailAlertResultsReducerActionTypes.UPDATE_ORDER_PARAMS:
            return {
                ...state,
                displayedResults: filterAndSortResults(state.fetchedResults, state.filterTerm, action.payload.sortOrder, action.payload.sortOrderBy),
                sortOrder: action.payload.sortOrder,
                sortOrderBy: action.payload.sortOrderBy,
            }
        case EmailAlertResultsReducerActionTypes.UPDATE_FILTER_PARAM:
            return {
                ...state,
                filterTerm: action.payload.filterTerm,
                displayedResults: filterAndSortResults(state.fetchedResults, action.payload.filterTerm, state.sortOrder, state.sortOrderBy),
            }
        case EmailAlertResultsReducerActionTypes.UPDATE_RESULTS: 
            return {
                ...state,
                fetchedResults: action.payload.results,
                displayedResults: filterAndSortResults(action.payload.results, initiaEmailAlertResultsContext.filterTerm, state.sortOrder, state.sortOrderBy),
                filterTerm: initiaEmailAlertResultsContext.filterTerm,
                totalItems: action.payload.totalItems,
                itemsOnCurrentPage: action.payload.itemsOnCurrentPage,
            }
        default:
            return initiaEmailAlertResultsContext;
    }
}

const EmailAlertResultsContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(emailAlertResultsReducer, initiaEmailAlertResultsContext);
    return (
        < EmailAlertResultsContext.Provider value={[state, dispatch]}>
            {children}
        </EmailAlertResultsContext.Provider>
    );
}
EmailAlertResultsContextProvider.propTypes = {
    children: PropTypes.node,
}

export default EmailAlertResultsContextProvider