import { Button, Card, Grid, makeStyles, Typography } from '@material-ui/core'
import CommonInput from '@mc/common/components/Input/CommonInput';
import React from 'react'
import { 
    dateRanges, 
    dynamicBannerTypes, 
    EmailAlertSearchContext, 
    intitialEmailAlertSearchState, 
    EmailAlertSearchReducerActionTypes
} from './EmailAlertSearchContext';


const useStyles = makeStyles((theme) => ({
    card: {
        padding: 20,
        borderRadius: 6,
    },
    inputField: {
        boxSizing:'border-box',
        height:32,
    },
    inputLabelText: {
        fontWeight: 600,
        paddingBottom: 10,
        textAlign: 'left'
    },
    dropdownInput:{
        width: '100%',
        fontSize: theme.typography.body1,
        paddingLeft:'5px',
        height: '32px',
        borderRadius: '6px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.secondary.main,
    },
    searchButton: {
        height: 32,
        borderRadius: 6,
        float: 'right',
        textTransform: 'none',
    },
}));

const SearchForm = () => {
    const classes = useStyles()

    const [searchContext, searchContextDispatch] = React.useContext(EmailAlertSearchContext)

    const [localSearchState, setLocalSearchState] = React.useState(intitialEmailAlertSearchState)

    const handleTextFieldChange = (field, newValue) => {
        if(Object.keys(searchContext.searchState).includes(field)){
            setLocalSearchState({
                ...localSearchState,
                [field]: newValue,
            })
        }
    }

    const handleDropdownChange = (field, val) => {
        if(field === 'dynamicBannerType'){
            setLocalSearchState({
                ...localSearchState,
                dynamicBannerType: dynamicBannerTypes[val]
            })
        }
        if(field === 'dateRange'){
            setLocalSearchState({
                ...localSearchState,
                dateRange: dateRanges[val]
            })
        }
    }

    const handleOnClear = () => {
        searchContextDispatch({
            type: EmailAlertSearchReducerActionTypes.RESET_EMAIL_SEARCH_STATE
        })
        setLocalSearchState(intitialEmailAlertSearchState)
    }

    const handleOnSearch = () => {
        searchContextDispatch({
            type: EmailAlertSearchReducerActionTypes.UPDATE_EMAIL_SEARCH_STATE,
            payload: localSearchState
        })
    }

    return (
        <Card className={classes.card}>
            <Grid container spacing={3} style={{padding:20}}  >
                <Grid item sm={12} md={4}>
                    <label>
                        <Typography className={classes.inputLabelText} color="textSecondary" variant="body2">
                            Message ID
                        </Typography>
                        <CommonInput
                            id='input_message_id'
                            className={classes.inputField} value={localSearchState.messageId} onChange={(value) => handleTextFieldChange('messageId', value )}
                        />
                    </label>
                </Grid>
                <Grid item sm={12} md={4}>
                    <label>
                        <Typography className={classes.inputLabelText} color="textSecondary" variant="body2">
                            From
                        </Typography>
                        <CommonInput
                            id='input_from'
                            className={classes.inputField} value={localSearchState.from} onChange={(value) => handleTextFieldChange('from', value )} 
                        />
                    </label>
                </Grid>
                <Grid item sm={12} md={4}>
                    <label>
                        <Typography className={classes.inputLabelText} color="textSecondary" variant="body2">
                            To
                        </Typography>
                        <CommonInput
                            id='input_to'
                            className={classes.inputField} value={localSearchState.to} onChange={(value) => handleTextFieldChange('to', value )}
                        />
                    </label>
                </Grid>
                <Grid item sm={12} md={4}>
                    <label>
                        <Typography className={classes.inputLabelText} color="textSecondary" variant="body2">
                            Subject
                        </Typography>
                        <CommonInput
                            id='input_subject'
                            className={classes.inputField} value={localSearchState.subject} onChange={(value) => handleTextFieldChange('subject', value )}
                        />
                    </label>
                </Grid>
                <Grid item sm={12} md={4}>
                    <label>
                        <Typography className={classes.inputLabelText} color="textSecondary" variant="body2">
                            Dynamic Banners
                        </Typography>
                        <select
                            id='select_dynamic_banner'
                            className={classes.dropdownInput}
                            value={localSearchState.dynamicBannerType.value}
                            onChange={elem => handleDropdownChange('dynamicBannerType', elem.target.value)}
                        >
                            {Object.entries(dynamicBannerTypes).map((dropdownItem, index) => (
                                <option key={index} value={dropdownItem[1].value}>{dropdownItem[1].label}</option>
                            ))}
                        </select>
                    </label>
                </Grid>
                <Grid item sm={12} md={4}>
                    <label>
                        <Typography className={classes.inputLabelText} color="textSecondary" variant="body2">
                            Date Range
                        </Typography>
                        <select
                            id='select_date_range'
                            className={classes.dropdownInput}
                            value={localSearchState.dateRange.value}
                            onChange={elem => handleDropdownChange('dateRange', elem.target.value)}
                            
                        >
                            {Object.entries(dateRanges).map((dropdownItem, index) => (
                                <option  key={index} value={dropdownItem[1].value} style={{paddingLeft: 5}}>{dropdownItem[1].label}</option>
                            ))}
                        </select>
                    </label>            
                </Grid>
                <Grid item xs={12}>
                    <Button data-testid="execute-search" onClick={handleOnSearch} className={classes.searchButton}  variant="contained" color="primary">
                        Search
                    </Button>
                    <Button data-testid="clear-search" onClick={handleOnClear} className={classes.searchButton} style={{marginRight: 10}}>
                        Clear
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default SearchForm
