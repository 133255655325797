import React from 'react'
import PropTypes from 'prop-types'

export const SELECT_EMAIL_ALERT = 'SELECT_EMAIL_ALERT' 
export const CLOSE_EMAIL_DETAILS = 'CLOSE_EMAIL_DETAILS'

export const EmailAlertDetailsContext = React.createContext()

export const initiaEmailAlertDetailsContext = {
    emailAlert: null,
    emailAlertIndex: 0, 
    detailsViewOpen: false
};


export const emailAlertDetailsReducer = (state, action) => {
    switch (action.type) {
        case SELECT_EMAIL_ALERT:
            return {
                ...state,
                emailAlert: action.emailAlert,
                emailAlertIndex: action.emailAlertIndex,
                detailsViewOpen: true
            }
        case CLOSE_EMAIL_DETAILS:
            return initiaEmailAlertDetailsContext
        default:
            return initiaEmailAlertDetailsContext;
    }
}

const EmailAlertDetailsContextProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(emailAlertDetailsReducer, initiaEmailAlertDetailsContext);
    return (
        < EmailAlertDetailsContext.Provider value={[state, dispatch]}>
            {children}
        </EmailAlertDetailsContext.Provider>
    );
}
EmailAlertDetailsContextProvider.propTypes = {
    children: PropTypes.node,
}

export default EmailAlertDetailsContextProvider