import Axios from "axios";
import { getRegionalizedUrl, TRACKING_API } from "@mc/common/lib/url-utils";
import {
    encodeUrlParams
} from "@mc/common/lib/url-params";

export const fetchCodebreakerDashboardData = (time_window, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/codebreaker/dashboard',
        data: encodeUrlParams({
            time_window: time_window || 'last_week',
            end_time: null,
            start_time: null,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const whitelistDomain = (domain, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/codebreaker/whitelist',
        data: encodeUrlParams({
            domain: domain,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const removeWhitelistedDomain = (domain, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/codebreaker/whitelist/delete',
        data: encodeUrlParams({
            domain: domain,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}

export const undoWhitelistedReport = (reportId, authState) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/codebreaker/whitelist/delete',
        data: encodeUrlParams({
            report_id: reportId,
            user_email: authState.email || '',
            session: authState.session || '',
        }),
        crossdomain: true,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    })
}