import React from 'react'
import { AppContext } from '../../../App/AppContext'
import { makeStyles, Card, Box, Grid, useTheme, Typography } from '@material-ui/core'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import PercentChangeText from '@mc/common/components/Text/PercentChangeText';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: 20,
        borderRadius: 6,

    },
    infoText: {
        paddingTop: 2,
        fontSize: 40,
        fontWeight: 600,
        lineHeight: "48px",
    },
    change: {
        display: 'inline-block',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: "24px",
    },
}));

const AlertGraphCard = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [appState,] = React.useContext(AppContext)
    return (
        <Card className={classes.card}>
            <Grid container spacing={3}>
                <Grid item lg={9}>
                    <Box style={{ width: "100%", height: 300 }}>
                        {(appState.emailAlertData.curr_window && appState.emailAlertData.curr_window.length) ? 
                            <ResponsiveContainer>
                                <BarChart
                                    data={appState.emailAlertData.curr_window}
                                >
                                    <CartesianGrid />
                                    <XAxis dataKey="date" />
                                    <YAxis style={{ fontFamily: 'Open Sans' }} label={{ value: 'Number of Dynamic Banners', angle: -90, position: 'insideBottomLeft' }} >
                                    </YAxis>
                                    <Tooltip />
                                    <Legend />
                                    <Bar name="Total Emails with Banners" dataKey="total_alerts" stackId="a" fill={theme.palette.primary.main} />
                                    <Bar name="Total Emails" dataKey="total_emails" stackId="a" fill={theme.palette.primary.light} />
                                </BarChart>
                            </ResponsiveContainer>
                        : 
                            <Typography variant="h6" style={{ 
                                textAlign: 'center', 
                                fontSize: '1rem',
                                fontWeight: 600,
                                top: '50%',
                                position: 'absolute',
                                left: '50%',
                            }}> NO DATA</Typography>
                        }
                    </Box>
                </Grid>
                <Grid item md={12} lg={3}>
                    <Typography variant='body2' style={{ fontWeight: 600 }}>
                        Average Ratio of Emails with Banners
                    </Typography>
                    <Box id='average_ratio_of_emails_with_banners_values' style={{ display: 'flex' }}>
                        <Typography className={classes.infoText}>
                            {`${(100 * appState.emailAlertData.alert_ratio || 0).toFixed(0)}%`}
                        </Typography>
                        <PercentChangeText score={appState.emailAlertData.alert_ratio_change !== null ? parseInt(appState.emailAlertData.alert_ratio_change) : 0} />
                    </Box>
                    <Typography variant='body2' style={{ fontWeight: 600, paddingTop: 20 }}>
                        Average Banners Per Inbox Per Day
                    </Typography>
                    <Box id='average_banners_per_inbox_per_day_values' style={{ display: 'flex' }}>
                        <Typography className={classes.infoText}>
                            {`${(appState.emailAlertData.average_alerts_per_inbox_per_day || 0).toFixed(0)}`}
                        </Typography>
                        <PercentChangeText score={appState.emailAlertData.average_alerts_per_inbox_per_day_change !== null ? parseInt(appState.emailAlertData.average_alerts_per_inbox_per_day_change) : 0} />
                    </Box>
                </Grid>
            </Grid>
        </Card >

    )
}

export default AlertGraphCard
